<template>
  <Dialog v-model:visible="display" :header="title" @hide="$emit('close')" :modal="true" class="p-fluid">
    <div class="p-fluid">
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>词条名称:</label>
        <div class="p-col">
          <InputText v-model="form.name" :class="{'p-invalid':(v$.form.name.$invalid && submitted)}"></InputText>
          <span v-if="v$.form.name.required.$invalid && submitted" style="font-size: 12px;color: red">请输入词条名称！</span>
        </div>
      </div>
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:100px;justify-content: flex-end"><span style="color:red;margin-right: 4px;">*</span>词条内容:</label>
        <div class="p-col">
          <InputText v-model="form.content" :class="{'p-invalid':(v$.form.content.$invalid && submitted)}"></InputText>
          <span v-if="v$.form.content.required.$invalid && submitted" style="font-size: 12px;color: red">请输入词条内容！</span>
        </div>
      </div>
    </div>
    <template #footer>
      <Button class="p-button-text" @click="cancel">取消</Button>
      <Button @click="submit(v$.$invalid)">保存</Button>
    </template>
  </Dialog>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup: () => ({
    v$: useVuelidate(),
  }),
  data() {
    return {
      title: null,
      display: false,
      submitted: false,
      form: {
        name: null,
        content: null,
        typeId: null,
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        content: { required },
      },
    };
  },
  methods: {
    init(id,data) {
      this.form.typeId = id;
      this.display = true;
      if (data) {
        this.title = "编辑词条";
        this.form=data
      } else {
        this.title = "新增词条";
      }
    },
    submit(isInvalid) {
      this.submitted = true;
      if (isInvalid) {
        return;
      }
      let that=this
      this.$http('/i18n/addOrUpdate', 'post', this.form, function () {
        that.$emit('id',that.form.typeId)
          that.display = false;
        })
    },
    cancel() {
      this.display = false;
    },
  },
};
</script>

<style scoped>
.p-fluid {
  width: 450px;
}

/*.p-field {*/
/*  margin: 0 auto 2rem;*/
/*}*/

label {
  width: 200px;
  text-align: right;
  justify-content: flex-end;
}

.p-field > span {
  width: 100%;
  padding-left: 200px;
  line-height: 34px;
  color: red;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
